exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-ai-pedagogy-js": () => import("./../../../src/pages/projects/ai-pedagogy.js" /* webpackChunkName: "component---src-pages-projects-ai-pedagogy-js" */),
  "component---src-pages-projects-chatgpt-app-js": () => import("./../../../src/pages/projects/chatgpt-app.js" /* webpackChunkName: "component---src-pages-projects-chatgpt-app-js" */),
  "component---src-pages-projects-deceptive-design-js": () => import("./../../../src/pages/projects/deceptive-design.js" /* webpackChunkName: "component---src-pages-projects-deceptive-design-js" */),
  "component---src-pages-projects-dining-hall-checkout-js": () => import("./../../../src/pages/projects/dining-hall-checkout.js" /* webpackChunkName: "component---src-pages-projects-dining-hall-checkout-js" */),
  "component---src-pages-projects-geography-of-terror-js": () => import("./../../../src/pages/projects/geography-of-terror.js" /* webpackChunkName: "component---src-pages-projects-geography-of-terror-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-kmdi-js": () => import("./../../../src/pages/projects/kmdi.js" /* webpackChunkName: "component---src-pages-projects-kmdi-js" */),
  "component---src-pages-projects-learn-from-failure-js": () => import("./../../../src/pages/projects/learn-from-failure.js" /* webpackChunkName: "component---src-pages-projects-learn-from-failure-js" */),
  "component---src-pages-projects-study-rooms-js": () => import("./../../../src/pages/projects/study-rooms.js" /* webpackChunkName: "component---src-pages-projects-study-rooms-js" */),
  "component---src-pages-projects-united-states-of-surveillance-js": () => import("./../../../src/pages/projects/united-states-of-surveillance.js" /* webpackChunkName: "component---src-pages-projects-united-states-of-surveillance-js" */)
}

